import React from "react"
import {ClientList} from '../components/clients/clientList'

import Layout from "../components/layout"

const IndexPage = () => (
  <Layout id="kite-homepage" seoTitle="Bespoke Software Development" title={<>Bespoke <strong>Software Development</strong></>}>
    <div className="text-high-height">
      For over 20 years we've been <strong>researching</strong>, <strong>conceptualising</strong>, <strong>defining</strong>, <strong>planning</strong>, <strong>documenting</strong>, <strong>designing</strong>, <strong>developing</strong>, <strong>updating</strong>, <strong>testing</strong>, <strong>enhancing</strong>, <strong>extending</strong>, <strong>deploying</strong>, <strong>supporting</strong> &amp; <strong>hosting</strong> hugely successful <strong>applications</strong>, <strong>sites</strong>, <strong>content</strong>, <strong>management systems</strong>, <strong>stores</strong>, <strong>games</strong>, <strong>bots</strong>, <strong>APIs</strong> &amp; <strong>data services</strong> deployed <strong>locally</strong> &amp; <strong>in the cloud</strong> for <strong>mobile</strong> &amp; <strong>big screen devices</strong>, via <strong>Agile</strong> &amp; <strong>Waterfall methodologies</strong>, working in collaboration with organisations like:
    </div>
    <ClientList />
    <div id="contact">
      If you are interested in working with us, <a href="mailto:kite@madebykite.com"><strong>we'd love to hear from you</strong></a>.
    </div>
  </Layout>
)

export default IndexPage
