import React from "react"
import {SVGElement} from "../svgs/svg"

const ClientList = () => (
  <div className="client-list">
    <div>
      <div><SVGElement name="bbc"/></div>
      <div><SVGElement name="cadbury"/></div>
      <div><SVGElement name="intel"/></div>
      <div><SVGElement name="adobe"/></div>
      <div><SVGElement name="nhs"/></div>
      <div><SVGElement name="royal_mail"/></div>
      <div><SVGElement name="cartoon_network"/></div>
      <div><SVGElement name="universal"/></div>
      <div><SVGElement name="rnli"/></div>
      <div><SVGElement name="discovery"/></div>
      <div><SVGElement name="wb"/></div>
      <div><SVGElement name="nestle"/></div>
    </div>
  </div>
)

export { ClientList }

